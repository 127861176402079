// Here you can add other styles

// https://flatuicolors.com/palette/defo

.cursor-pointer {
  cursor: pointer;
}

.offcanvas-600 {
  --cui-offcanvas-width: 600px;
}

.nav-font {
  --cui-nav-link-font-weight: 500;
}

.bg-eletric-blue {
  background-color: #577482;
}

.bg-steel-teal {
  background-color: #658594;
}

.bg-hover-success:hover {
  background-color: #2eb85c;
}

.bg-hover-info:hover {
  background-color: #3399ff;
}

.bg-hover-danger:hover {
  background-color: #e65353;
}

.bg-hover-warning:hover {
  background-color: #f39c12;
}

.bg-hover-wisteria:hover {
  background-color: #8e44ad !important;
}

.bg-wisteria {
  background-color: #8e44ad !important;
}

.bg-wisteria-light {
  background-color: #ffe8ff !important;
}

.bg-hover-white:hover .icon {
  color: white !important;
}

.datePicker {
  width: 100%;
}

.disabled-element {
  pointer-events: none;
  opacity: 0.4;
}

.z-500 {
  z-index: 500;
}

.no-item {
  width: 100%;
  height: 70px;
  border: 1px dashed #352f2f;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  color: #999999;
}

.vertical-line {
  border: 1px dashed #aeaeae;
  margin-top: 20px;
}

.label-font-smaller {
  font-size: smaller;
  margin-bottom: 0px;
}

.label-font-smaller-2 {
  font-size: 12px;
  margin-bottom: -10px;
}

.label-margin-bottom-5 {
  margin-bottom: 5px;
}

.label-margin-bottom-2 {
  margin-bottom: 2px;
}

.block-box {
  border: 3px solid gray;
}

.lf-14 {
  font-size: 14px;
}

.lf-12 {
  font-size: 12px;
}

.tooltip-custom {
  --cui-tooltip-opacity: 1;
  --cui-tooltip-bg: '#ebecf0';
}
